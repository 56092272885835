<template>
  <div class="minicart-container">
    <a
      class="page-actions__action nav-profile-menu__trigger"
      :href="props.checkoutUrl"
    >
      <template v-if="productPageStore.cartEmpty">
        <Icon :type="IconTypes.BagEmpty" :size="IconSizes.MiniCartEmpty" />
      </template>
      <template v-else>
        <Icon :type="IconTypes.BagEmpty" :size="IconSizes.MiniCartEmpty" />
        <span class="page-actions__action-amount">{{
          productPageStore.cartItemCount
        }}</span>
      </template>

      <span class="page-actions__action-label u-hide-for-medium-down"
        >Handlekurv</span
      ></a
    >
  </div>
</template>

<script setup lang="ts">
import { onMounted } from "vue";
import Icon from "../../components/icon/icon.vue";
import { IconSizes, IconTypes } from "../../components/icon/icon.model";
import { useProductPageStore } from "../../stores/productPageStore";

const props = defineProps<{
  checkoutUrl: string;
  useMinicartOnMobileString?: string;
}>();

const productPageStore = useProductPageStore();

const refreshCartState = async () => {
  try {
    const response = await fetch("/Cart/MiniCartContent");
    if (!response.ok) throw new Error("Failed to fetch cart data.");
    const data = await response.json();
    productPageStore.setCartItemCount(data.itemCount);
  } catch (error) {
    console.error("Error refreshing cart state:", error);
  }
};

onMounted(async () => {
  await refreshCartState();
});
</script>
