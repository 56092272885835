import { defineStore } from "pinia";
import { useLocalStorage } from "@vueuse/core";
import { useUpsell } from "../composables/useUpsell";

export const useProductPageStore = defineStore("productPage", {
    state: () => ({
        totalProductCount: useLocalStorage("totalProductCount", 0), // Persisted in localStorage
        sizeSelected: false,
        widthSelected: false,
        lengthSelected: false,
        sizeText: "",
        skuCode: "",
        displaySizeNotSelectedError: false,
        displayWidthNotSelectedError: false,
        displayLengthNotSelectedError: false,
        checkInventoryStatus: false,
        inventoryCount: "",
        inventoryIsLow: false,
        inventoryLowMessage: "Få igjen!",
        itemColor: "",
        itemMetaColor: "",
        displayName: "",
        cartStatusIsOpen: false,
        cartStatusUpsell: useUpsell(),
    }),
    actions: {
        setCartItemCount(count: number) {
            this.totalProductCount = count;
        },
        incrementCartItemCount() {
            if (this.totalProductCount === null) {
                this.totalProductCount = 1;
            } else {
                this.totalProductCount = Number(this.totalProductCount) + 1;
            }
        },
        decrementCartItemCount() {
            if (
                this.totalProductCount === null ||
                this.totalProductCount === 0
            ) {
                this.totalProductCount = 0;
            } else {
                this.totalProductCount = Number(this.totalProductCount) - 1;
            }
        },
        setSkuCode(value: string) {
            this.skuCode = value;
        },
        setSizeSelected(selected: boolean) {
            this.sizeSelected = selected;
            if (selected) {
                this.displaySizeNotSelectedError = false;
            }
        },
        setWidthSelected(selected: boolean) {
            this.widthSelected = selected;
            if (selected) {
                this.displayWidthNotSelectedError = false;
            }
        },
        setLengthSelected(selected: boolean) {
            this.lengthSelected = selected;
            if (selected) {
                this.displayLengthNotSelectedError = false;
            }
        },
        setCheckInventoryStatus(status: boolean) {
            this.checkInventoryStatus = status;
        },
        setInventoryCount(count: string) {
            this.inventoryCount = count;
        },
        setInventoryIsLow(status: boolean) {
            this.inventoryIsLow = status;
        },
        triggerSizeError() {
            this.displaySizeNotSelectedError = true;
        },
        setSizeText(text: string) {
            this.sizeText = text;
        },
        setItemColor(color: string) {
            this.itemColor = color;
        },
        setItemMetaColor(color: string) {
            this.itemMetaColor = color;
        },
        setDisplayName(name: string) {
            this.displayName = name;
        },
        setCartStatusIsOpen(status: boolean) {
            this.cartStatusIsOpen = status;
        },
    },
    getters: {
        cartEmpty: (state) => {
            return state.totalProductCount === 0;
        },
        cartItemCount: (state) => {
            return state.totalProductCount;
        },
    },
});
