<script lang="ts" setup>
import { computed, ref, watch } from "vue";
import ProductsModuleContent from "../productsModule/productsModuleContent.vue";
import Icon from "../../components/icon/icon.vue";
import { useProductPageStore } from "../../stores/productPageStore";

const props = defineProps<{
  checkoutLink: string;
  displayName: string;
  cartStatusImage: string;
  userAuthenticated?: string;
  recommendationsHeading?: string;
}>();

const productPageStore = useProductPageStore();
const dialogElement = ref();
const isUserAuthenticated = computed(() => props.userAuthenticated === "True");

watch(
  () => productPageStore.cartStatusIsOpen,
  (newValue, oldValue) => {
    if (!dialogElement.value || newValue === oldValue) return;

    newValue ? dialogElement.value.showModal() : dialogElement.value.close();
  },
);

const handleClickClose = () => {
  productPageStore.setCartStatusIsOpen(false);
};

const handleClickOutsideDialog = (event) => {
  // Close the dialog if the user clicks outside of the content area
  if (event.target === dialogElement.value) {
    handleClickClose();
  }
};
</script>

<template>
  <dialog
    class="cart-status"
    aria-live="polite"
    :aria-hidden="!productPageStore.cartStatusIsOpen"
    role="dialog"
    ref="dialogElement"
    aria-labelledby="cart-status-heading"
    @click="(e) => handleClickOutsideDialog(e)"
  >
    <article class="cart-status__content">
      <span class="cart-status__message">{{ $t("addedToCart") }}</span>
      <div class="cart-status__product">
        <div class="cart-status__image">
          <picture>
            <source type="image/webp" :srcset="props.cartStatusImage" />
            <img
              alt=""
              width="auto"
              height="auto"
              loading="lazy"
              :src="props.cartStatusImage"
            />
          </picture>
        </div>
        <header class="cart-status__text">
          <div>
            <button
              class="btn btn--small btn--link btn--close cart-status__close"
              type="button"
              @click.prevent="handleClickClose"
              :title="$t('close')"
            >
              <Icon type="close" />
            </button>
            <h3 class="cart-status__heading">
              {{ productPageStore.displayName }}
            </h3>
            <p class="cart-status__desc">
              {{ $t("color") }}: {{ productPageStore.itemColor }} ({{
                productPageStore.itemMetaColor
              }})
              <br />
              <span> {{ $t("size") }}: {{ productPageStore.sizeText }} </span>
            </p>
          </div>
          <div class="cart-status__buttons">
            <div>
              <button
                class="btn btn--white cart-status__btn"
                @click.prevent="handleClickClose"
              >
                {{ $t("continueShopping") }}
              </button>
            </div>
            <div>
              <a
                :href="props.checkoutLink"
                :autofocus="true"
                @click="handleClickClose"
                class="btn btn--profile cart-status__btn u-trailer-half"
                >{{ $t("goToCheckout") }}</a
              >
            </div>
          </div>
        </header>
      </div>
      <div class="layout-product__related">
        <ProductsModuleContent
          container-class="product-list__upsell-small"
          :search-result-recommend-list="
            productPageStore?.cartStatusUpsell.upsellModel
          "
          :is-loading="productPageStore.cartStatusUpsell.isLoading"
          :heading="props.recommendationsHeading ?? $t('maybeYouAlsoLike')"
          :hide-colors="true"
          :hide-favorites="true"
          :hide-promotion="false"
          :hide-sizes="true"
          :limit="4"
          :track-view-item-list="true"
          :track-select-item="true"
          :upsell-algorithm="'upsell'"
          :is-loading-favorites="false"
          :user-authenticated="isUserAuthenticated"
          item-list-name="cartstatus-upsell"
          item-list-id="cartstatus-upsell"
        >
        </ProductsModuleContent>
      </div>
    </article>
  </dialog>
</template>
