<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref, watch } from "vue";
import { formatWithoutRounding } from "../../mixins/productPrice.mixin";
import ShoppingCartSummary from "./shoppingCartSummary.vue";
import { formatCurrency } from "../../utils/currencyHelpers";
import ShoppingCartKsps from "./shoppingCartKsps.vue";
import ShoppingCartDiscounts from "./shoppingCartDiscounts.vue";
import autoAnimate from "@formkit/auto-animate";
import LineItem from "./lineItem.vue";
import { API_ERROR_MESSAGES } from "../../utils/API/APIErrors";
import LineItemSkeleton from "./lineItemSkeleton.vue";
import TopTextSkeleton from "./topTextSkeleton.vue";
import PaymentProviders from "./paymentProviders.vue";
import { CheckoutStages } from "../../services/models/checkout.models";
import { qualifiesForFreeShipping } from "../../.././../helpers/checkoutHelpers";
import { useCheckoutStateStore } from "../checkout/stores/checkoutStateStore";
import * as CheckoutService from "../../services/checkout.service";
import { useShoppingCartStore } from "./stores/shoppingCartStore";
import ShoppingCartService from "../../services/shoppingcart.service";
import ProductsModuleContent from "../../views/productsModule/productsModuleContent.vue";
import { useUpsellStore } from "../checkout/stores/upsellStore";
import Searchbox from "../searchbox/searchbox.vue";

const props = defineProps<{
  ksps: string | null; // JSON Content from NewCheckoutIndex.cshtml
  oldCheckoutUrl: string | null;
  maxCartSize?: string;
  initialLineItemCount?: string; // Number of line items in the cart when the component is mounted
  baseUrlPath: string;
}>();

const MOBILE_UPSELL_DISPLAY_TRESHOLD = 1023;
const shoppingCartStore = useShoppingCartStore();
const checkoutStateStore = useCheckoutStateStore();
const INITIAL_LINE_ITEM_COUNT = ref(Number(props.initialLineItemCount) || 3);
const MAX_CART_SIZE = ref(Number(props.maxCartSize) || 99);
const inStockListElement = ref();
const outOfStockListElement = ref();

watch(
  () => shoppingCartStore.cart?.itemCount,
  (newVal) => {
    localStorage.setItem("totalProductCount", JSON.stringify(newVal));
  },
  { deep: true },
);

const mobileUpsellVisible = ref(
  window.innerWidth < MOBILE_UPSELL_DISPLAY_TRESHOLD,
);
const handleResize = () => {
  mobileUpsellVisible.value =
    window.innerWidth < MOBILE_UPSELL_DISPLAY_TRESHOLD;
};

/**
 * Computed property that returns the line items in stock.
 *
 * @returns {Array} The line items in stock.
 */
const lineItemsInStock = computed(() => {
  return shoppingCartStore.cart?.lineItems.filter(
    (lineItem) => lineItem.quantity <= lineItem.currentInventory,
  );
});

/**
 * Computes whether free shipping is applicable for the shopping cart.
 *
 * @returns {boolean} True if free shipping is applicable, false otherwise.
 */
const freeShipping = computed(() => {
  if (!shoppingCartStore.cart) return false;

  return qualifiesForFreeShipping(
    shoppingCartStore.cart.freeShippingActive,
    shoppingCartStore.cart.freeShippingLimit,
    shoppingCartStore.cart.subTotal,
  );
});

/**
 * Computes the current size of the shopping cart. (Adds together all quantity values of lineItems)
 *
 * @returns {number} The current size of the shopping cart.
 */
const currentCartSize = computed(() => {
  return (
    lineItemsInStock.value?.reduce(
      (acc, lineItem) => acc + lineItem.quantity,
      0,
    ) || 0
  );
});

/**
 * Determines if the shopping cart is full.
 * @returns {boolean} True if the shopping cart is full, false otherwise.
 */
const cartIsFull = computed(() => {
  if (!currentCartSize.value) return false;
  return currentCartSize.value >= MAX_CART_SIZE.value;
});

/**
 * Computed property for the top text in the shopping cart view on mobile viewports.
 * Conditionally contains shipping cost if the cart qualifies for free shipping.
 *
 * @returns {string} The top text value.
 */
const topText = computed(() => {
  if (!shoppingCartStore.cart) return;

  const baseText = `${currentCartSize.value} varer / Totalsum: ${formatWithoutRounding(shoppingCartStore.cart.total.amount)}`;
  return freeShipping.value ? `${baseText} (Fri frakt)` : baseText;
});

/**
 * If the new cart value contains item(s) with a quantity that exceeds the current inventory, add it to the LineItemsOutOfStock array if it's not already there.
 * Also remove the item from the cart so that the summary is updated.
 *
 * @param {Object} cart - The new value of the 'cart' property.
 */
watch(
  () => shoppingCartStore.cart,
  (newValue) => {
    newValue?.lineItems.forEach((lineItem) => {
      if (lineItem.quantity <= lineItem.currentInventory) return;

      if (
        !shoppingCartStore.lineItemsOutOfStock?.some(
          (x) => x.ean === lineItem.ean,
        )
      ) {
        shoppingCartStore.lineItemsOutOfStock.push(lineItem);
      }

      ShoppingCartService.apiRemoveProductFromCart(lineItem.code, lineItem.ean);
    });
  },
);

/**
 * If cartIsFull becomes false, remove the summaryError value.
 *
 * @param {boolean} newValue - The new value of the 'cartIsFull' property.
 */
watch(cartIsFull, (newValue) => {
  if (!newValue) {
    shoppingCartStore.setSummaryError("");
  }
});

/**
 * Clears the discountError value.
 */
const handleResetDiscountError = () => {
  shoppingCartStore.setDiscountError("");
};

const handleGoToCheckout = () => {
  CheckoutService.goToCheckoutStage(CheckoutStages.Verification);
};

onMounted(() => {
  window.addEventListener("resize", handleResize);

  if (props.ksps) {
    shoppingCartStore.setKsps(JSON.parse(props.ksps));
  }

  if (props.baseUrlPath) {
    checkoutStateStore.setBaseUrlPath(props.baseUrlPath);
  }

  autoAnimate(inStockListElement.value);
  autoAnimate(outOfStockListElement.value);
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", handleResize);
});
</script>

<template>
  <div>
    <div class="layout-new-checkout__top">
      <h1>{{ $t("shoppingCart") }}</h1>
      <div class="layout-new-checkout__top-content">
        <span v-if="topText" class="layout-new-checkout__top-text">{{
          topText
        }}</span>
        <TopTextSkeleton
          v-if="!shoppingCartStore.cart && checkoutStateStore.isLoading"
          class="layout-new-checkout__top-text"
        />
      </div>
    </div>
    <div class="layout-new-checkout">
      <div class="layout-new-checkout__heading">
        <h1>{{ $t("shoppingCart") }}</h1>
      </div>
      <div class="layout-new-checkout__main">
        <div class="layout-new-checkout__vertical-split">
          <div
            class="layout-new-checkout__primary layout-new-checkout__element"
          >
            <div
              class="layout-new-checkout__primary-content layout-new-checkout__primary-content--shoppingcart"
            >
              <h2 class="sr-only">{{ $t("products") }}</h2>
              <ul
                ref="outOfStockListElement"
                :aria-hidden="!shoppingCartStore.lineItemsOutOfStock?.length"
                class="checkout-line-items checkout-line-items--out-of-stock"
              >
                <LineItem
                  v-for="lineItem in shoppingCartStore.lineItemsOutOfStock"
                  :key="lineItem.ean"
                  :line-item="lineItem"
                  :out-of-stock="true"
                  :error="API_ERROR_MESSAGES.OUT_OF_STOCK"
                ></LineItem>
              </ul>
              <ul
                ref="inStockListElement"
                :aria-hidden="!shoppingCartStore.lineItemsOutOfStock?.length"
                class="checkout-line-items"
              >
                <LineItem
                  v-for="lineItem in lineItemsInStock"
                  :key="lineItem.ean"
                  :line-item="lineItem"
                  :error="shoppingCartStore.lineItemErrors?.[lineItem.ean]"
                ></LineItem>
              </ul>
              <div
                v-if="!shoppingCartStore.cart && checkoutStateStore.isLoading"
                class="checkout-line-items"
              >
                <LineItemSkeleton
                  v-for="(_, index) in INITIAL_LINE_ITEM_COUNT"
                  :key="index"
                ></LineItemSkeleton>
              </div>
              <template
                v-if="
                  !lineItemsInStock?.length &&
                  !shoppingCartStore.lineItemsOutOfStock?.length &&
                  !checkoutStateStore.isLoading
                "
              >
                <h3 class="layout-new-checkout__primary-placeholder">
                  {{ $t("shoppingCartEmpty") }}
                </h3>
              </template>
            </div>
            <ProductsModuleContent
              v-show="!mobileUpsellVisible"
              :heading="$t('maybeYouAlsoLike')"
              :search-result-recommend-list="
                shoppingCartStore?.upsell.upsellModel
              "
              :user-authenticated="
                shoppingCartStore.cart?.isAuthenticated ?? false
              "
              :favorite-form-hidden="true"
              :is-loading="shoppingCartStore?.upsell.isLoading"
              :hide-sizes="true"
              :hide-colors="true"
              :hide-favorites="true"
              :productCount="shoppingCartStore?.upsell.productCount"
              :use-skeleton-loaders="true"
              :track-view-item-list="!mobileUpsellVisible"
              :track-select-item="true"
              item-list-id="shoppingcart-upsell"
              item-list-name="shoppingcart-upsell"
              upsell-algorithm="upsell"
              container-class="product-list__upsell-small product-list__upsell-small--desktop"
            />
          </div>
          <div class="layout-new-checkout__secondary">
            <div
              v-if="lineItemsInStock?.length"
              class="layout-new-checkout layout-new-checkout__element layout-new-checkout__element-small"
            >
              <h2 class="accordion-new__heading">Legg til rabattkode</h2>
              <ShoppingCartDiscounts
                :discounts="shoppingCartStore.cart?.appliedCoupons || {}"
                @resetDiscountError="handleResetDiscountError"
                @apiAddDiscountCode="ShoppingCartService.apiAddDiscountCode"
                @apiRemoveDiscountCode="
                  ShoppingCartService.apiRemoveDiscountCode
                "
              />
            </div>
            <div
              v-if="shoppingCartStore.cart"
              class="layout-new-checkout__summary layout-new-checkout__element"
            >
              <ShoppingCartSummary
                :cart="shoppingCartStore.cart"
                :error="shoppingCartStore.summaryError || ''"
                :show-checkout-link="!!shoppingCartStore.cart?.itemCount"
              />
            </div>
            <ProductsModuleContent
              v-show="mobileUpsellVisible"
              :heading="$t('maybeYouAlsoLike')"
              :search-result-recommend-list="
                shoppingCartStore?.upsell.upsellModel
              "
              :show-as-column-on-mobile="false"
              :user-authenticated="
                shoppingCartStore.cart?.isAuthenticated ?? false
              "
              :favorite-form-hidden="true"
              :is-loading="shoppingCartStore?.upsell.isLoading"
              :hide-sizes="true"
              :hide-colors="true"
              :hide-favorites="true"
              :productCount="shoppingCartStore?.upsell.productCount"
              :use-skeleton-loaders="true"
              :track-view-item-list="mobileUpsellVisible"
              :track-select-item="true"
              item-list-id="shoppingcart-upsell"
              item-list-name="shoppingcart-upsell"
              upsell-algorithm="upsell"
              container-class="product-list__upsell-small product-list__upsell-small--mobile"
            />
            <ShoppingCartKsps
              v-if="shoppingCartStore.ksps"
              :key-selling-points="shoppingCartStore.ksps"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="shoppingCartStore.cart?.subTotal?.amount && props.oldCheckoutUrl"
      class="layout-new-checkout__summary-fixed"
    >
      <h4 class="layout-new-checkout__summary-fixed-heading">
        <span>{{ $t("total") }}: </span>
        <span
          >{{
            shoppingCartStore.cart.total &&
            formatCurrency(shoppingCartStore.cart.total.currency)
          }}
          {{
            shoppingCartStore.cart.total &&
            formatWithoutRounding(shoppingCartStore.totalAmount)
          }}</span
        >
      </h4>
      <button
        v-if="shoppingCartStore.cart?.itemCount"
        class="btn btn--profile btn--checkout checkout-form__submit"
        @click="handleGoToCheckout"
      >
        {{ $t("toCheckout") }}
      </button>
      <PaymentProviders
        v-if="shoppingCartStore.cart?.paymentMethods"
        :payment-methods="shoppingCartStore.cart.paymentMethods"
      ></PaymentProviders>
    </div>
  </div>
</template>
