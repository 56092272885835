<template>
  <form @submit.prevent="submitForm">
    <div class="product__size">
      <SizePickerSuit
        v-if="props.sizePickerType === SizePickerTypes.SizePickerSuit"
        v-bind="sizePickerSuitProps"
      />
      <SizePickerPants
        v-if="props.sizePickerType === SizePickerTypes.SizePickerPants"
        v-bind="sizePickerPantsProps"
      />
      <SizePickerRegular
        v-if="props.sizePickerType === SizePickerTypes.SizePickerRegular"
        v-bind="sizePickerRegularProps"
      />
    </div>
    <!-- Display modelSizeInformation if it's not an empty string -->
    <div v-if="modelSizeInformation" class="product__model-information">
      {{ modelSizeInformation }}
    </div>

    <div v-if="isProductInStockBool" class="product__add">
      <input type="hidden" value="1" :name="skuParameterCode" />
      <button
        class="btn btn--profile btn--inset u-display-block btn--add-to-cart product__add-to-cart-btn"
        type="submit"
      >
        {{ addToBasket }}
      </button>
    </div>

    <!-- Disabled button for out-of-stock case -->
    <div v-else class="product__add">
      <button
        class="btn btn--add-to-cart u-display-block product__add-to-cart-btn"
        type="submit"
        disabled
      >
        {{ addToBasket }}
      </button>

      <!-- Sold out message only if product available in physical store -->
      <div v-if="isProductAvailableInStoreBool" class="product__soldout">
        <span class="product__soldout--text">{{ soldoutNews }}</span>
      </div>
    </div>
  </form>
  <CartStatus
    :display-name="props.displayName"
    :item-color="props.itemColor"
    :item-meta-color="props.itemMetaColor"
    :cart-status-image="props.cartStatusImage"
    :checkout-link="props.checkoutLink"
  />
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, watch } from "vue";
import SizePickerSuit from "./sizePickerSuit.vue";
import SizePickerPants from "./sizePickerPants.vue";
import SizePickerRegular from "./sizePickerRegular.vue";
import { useProductPageStore } from "../../stores/productPageStore";
import { LogErrorResponse } from "../../utils/API/Logging/LoggingAPI";
import { vueComponentToggler } from "../../../../global/modules/toggler_wp";
import { vueInventory } from "../../../../global/modules/inventory_wp";
import CartStatus from "../../views/cartStatus/cartStatus.vue";
import { GoogleAnalytics4EventModelSchema } from "../../utils/API/GoogleAnalytics/GoogleAnalyticsSchema";
import SearchService, {
  AutocompleteResult,
  CONTENT_LIST_ARTICLES,
  CONTENT_LIST_STORES,
  ContentList,
  ContentItem,
} from "../../services/search.service";
import { handleGtmTracking } from "../../utils/API/GTM";
import Toast from "../../mixins/toast";

const productPageStore = useProductPageStore();
const toast = Toast.getInstance();
const CONTROLLER_URL = "/Cart/AddToCart";

const props = defineProps<{
  sizePickerType: string; // 'suit', 'pants', or 'regular'
  sizesMatrixJson: string;
  modelSizeInformation: string;
  chooseSize: string;
  chooseWidth: string;
  chooseLength: string;
  sizeGuide: string;
  isSizeGuide: string;
  selectSizeMessage: string;
  selectWidthMessage: string;
  selectLengthMessage: string;
  skuParameterCode: string;
  sizesListJson?: string; // Only needed for sizePickerRegular
  addToBasket: string;
  isProductInStock: string;
  soldoutNews: string;
  productAvailableInStore: string;
  inventoryNotificationTrigger: string;
  displayName: string;
  itemColor: string;
  itemMetaColor: string;
  cartStatusImage: string;
  checkoutLink: string;
  esalesKey: string;
  fewItemsLeftThreshold: string;
}>();

const SizePickerTypes = {
  SizePickerSuit: "suit",
  SizePickerPants: "pants",
  SizePickerRegular: "regular",
} as const;

const isProductInStockBool = computed(() => {
  return props.isProductInStock === "True";
});

const isProductAvailableInStoreBool = computed(() => {
  return props.productAvailableInStore === "True";
});

const commonProps = {
  sizeGuide: props.sizeGuide,
  isSizeGuide: props.isSizeGuide === "True",
  skuParameterCode: props.skuParameterCode,
  modelSizeInformation: props.modelSizeInformation,
  inventoryNotificationTrigger: props.inventoryNotificationTrigger,
};

const sizePickerRegularProps = computed(() => {
  return {
    ...commonProps,
    sizesListJson: props.sizesListJson,
    chooseSize: props.chooseSize,
    selectSizeMessage: props.selectSizeMessage,
  };
});

const sizePickerPantsProps = computed(() => {
  return {
    ...commonProps,
    sizesMatrixJson: props.sizesMatrixJson,
    chooseWidth: props.chooseWidth,
    chooseLength: props.chooseLength,
    selectWidthMessage: props.selectWidthMessage,
    selectLengthMessage: props.selectLengthMessage,
  };
});

const sizePickerSuitProps = computed(() => {
  return {
    ...commonProps,
    sizesMatrixJson: props.sizesMatrixJson,
    chooseSize: props.chooseSize,
    chooseWidth: props.chooseWidth,
    chooseLength: props.chooseLength,
    selectSizeMessage: props.selectSizeMessage,
    selectWidthMessage: props.selectWidthMessage,
    selectLengthMessage: props.selectLengthMessage,
  };
});

const validateRegularSizeSelection = () => {
  if (!productPageStore.sizeSelected) {
    productPageStore.displaySizeNotSelectedError = true;
    return false;
  } else {
    productPageStore.displaySizeNotSelectedError = false;
    return true;
  }
};

const validatePantsSizeSelection = () => {
  if (productPageStore.widthSelected && productPageStore.lengthSelected) {
    productPageStore.displayWidthNotSelectedError = false;
    productPageStore.displayLengthNotSelectedError = false;
    return true;
  } else {
    if (!productPageStore.widthSelected) {
      productPageStore.displayWidthNotSelectedError = true;
    } else if (!productPageStore.lengthSelected) {
      productPageStore.displayLengthNotSelectedError = true;
    }
    if (!productPageStore.lengthSelected) {
      productPageStore.displayLengthNotSelectedError = true;
    } else if (!productPageStore.widthSelected) {
      productPageStore.displayWidthNotSelectedError = true;
    }
    return false;
  }
};

const validateSuitSizeSelection = () => {
  if (
    productPageStore.sizeSelected &&
    productPageStore.widthSelected &&
    productPageStore.lengthSelected
  ) {
    productPageStore.displaySizeNotSelectedError = false;
    productPageStore.displayWidthNotSelectedError = false;
    productPageStore.displayLengthNotSelectedError = false;
    return true;
  } else {
    if (!productPageStore.sizeSelected) {
      productPageStore.displaySizeNotSelectedError = true;
    } else if (productPageStore.sizeSelected) {
      productPageStore.displaySizeNotSelectedError = false;
    }
    if (!productPageStore.widthSelected) {
      productPageStore.displayWidthNotSelectedError = true;
    } else if (productPageStore.widthSelected) {
      productPageStore.displayWidthNotSelectedError = false;
    }
    if (!productPageStore.lengthSelected) {
      productPageStore.displayLengthNotSelectedError = true;
    } else if (productPageStore.lengthSelected) {
      productPageStore.displayLengthNotSelectedError = false;
    }
    return false;
  }
};

// Validate size selection based on sizePickerType
const validateSizeSelection = () => {
  switch (props.sizePickerType) {
    case "suit":
      if (validateSuitSizeSelection()) return true;
      break;
    case "pants":
      if (validatePantsSizeSelection()) return true;
      break;
    case "regular":
      if (validateRegularSizeSelection()) return true;
    default:
      return false;
  }
};

function displayCartStatus() {
  productPageStore.setCartStatusIsOpen(true);
}

async function checkStockStatus(count: string): Promise<void> {
  const inventoryIsLow =
    parseInt(count) <= parseInt(props.fewItemsLeftThreshold);

  if (inventoryIsLow) {
    productPageStore.inventoryIsLow = true;
  } else {
    productPageStore.inventoryIsLow = false;
  }

  productPageStore.setCheckInventoryStatus(false);
}

async function addToCart(): Promise<void> {
  const url = `${CONTROLLER_URL}`;
  const formData = {
    c: productPageStore.skuCode,
    q: 1,
  };
  const body = JSON.stringify(formData);

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: body,
  });

  if (!response.ok || response.status !== 200) {
    await LogErrorResponse(url, response, body);
    toast.show("Det skjedde en feil. Prøv igjen senere", true);
    return Promise.reject("Det skjedde en feil. Prøv igjen senere");
  }

  displayCartStatus();
  const data = await response.json();
  const validatedData = GoogleAnalytics4EventModelSchema.parse(data);
  handleGtmTracking(validatedData);
  productPageStore.incrementCartItemCount();
}

const getCartStatusUpsell = async () => {
  productPageStore.cartStatusUpsell.isLoading = true;

  await SearchService.products(
    `/publicapi/esales/upsell?productKey=${props.esalesKey}`,
    "",
    "4",
  ).then((response) => {
    productPageStore.cartStatusUpsell.setUpsellModel(response.data);
  });

  productPageStore.cartStatusUpsell.isLoading = false;
};

const submitForm = () => {
  if (!validateSizeSelection()) {
    return;
  }

  addToCart();
};

onMounted(() => {
  vueComponentToggler();
  vueInventory();
  getCartStatusUpsell();

  productPageStore.setItemColor(props.itemColor);
  productPageStore.setItemMetaColor(props.itemMetaColor);
  productPageStore.setDisplayName(props.displayName);
  productPageStore.setCartStatusIsOpen(false);
});

watch(
  () => productPageStore.checkInventoryStatus,
  (newValue) => {
    if (newValue === true) {
      // Trigger the API call with the inventory count value
      checkStockStatus(productPageStore.inventoryCount);
    }
  },
);
</script>
