<script setup lang="ts">
import { ref, onMounted, watch, onUnmounted } from "vue";
import autoAnimate from "@formkit/auto-animate";
import Icon from "../../components/icon/icon.vue";
import ShoppingCartError from "./shoppingCartError.vue";
import { useShoppingCartStore } from "./stores/shoppingCartStore";
import ShoppingCartService from "../../services/shoppingcart.service";

const props = defineProps<{
  discounts: Record<string, string>;
}>();

const shoppingCartStore = useShoppingCartStore();
const discountsList = ref();
const root = ref();
const discountCodeInputRef = ref();

// Watch props.discounts old and new value. If props.discounts has more keys than the old value, clear the input.
watch(
  () => props.discounts,
  (newVal, oldVal) => {
    if (Object.keys(newVal).length > Object.keys(oldVal).length) {
      discountCodeInputRef.value.value = "";
    }
  },
  { deep: true },
);

/**
 * Resets discount error if the discount input is empty.
 */
const handleDiscountInput = () => {
  if (discountCodeInputRef.value.value.trim() === "")
    shoppingCartStore.setDiscountError("");
};

/**
 * Handles the event when a discount code is added.
 *
 * @param {Event} e - The event object.
 */
const handleAddDiscountCode = (e: Event) => {
  e.preventDefault();

  const discountCode = discountCodeInputRef.value.value.trim();
  if (!discountCode) return;

  shoppingCartStore.setDiscountError("");
  ShoppingCartService.apiAddDiscountCode(discountCode);
};

/**
 * Handles the event when a discount code is removed.
 *
 * @param {Event} e - The event object.
 * @param {string} discountCode - The discount code to be removed.
 * @returns {Promise<void>} - A promise that resolves when the discount code is removed.
 */
const handleRemoveDiscountCode = async (
  e: Event,
  discountCode: string,
): Promise<void> => {
  e.preventDefault();
  ShoppingCartService.apiRemoveDiscountCode(discountCode);
};

onMounted(() => {
  autoAnimate(root.value);
  autoAnimate(discountsList.value);
});

onUnmounted(() => {
  shoppingCartStore.setDiscountError("");
});
</script>

<template>
  <div ref="root" class="checkout-discounts">
    <form>
      <fieldset>
        <legend class="sr-only">
          {{ $t("discountCodes") }}
          <Icon size="small" type="discount" />
        </legend>
        <label class="sr-only" for="discount-input">{{ $t("code") }}</label>
        <div class="checkout-discounts__form">
          <input
            id="discount-input"
            ref="discountCodeInputRef"
            class="checkout-discounts__input"
            type="text"
            value=""
            placeholder="Rabattkode"
            :aria-describedby="
              shoppingCartStore.discountError ? 'discount-error' : ''
            "
            @input="handleDiscountInput"
          />
          <button
            class="btn btn--profile btn--small checkout-discounts__button"
            @click="(event) => handleAddDiscountCode(event)"
          >
            <span>{{ $t("use") }}</span>
          </button>
        </div>
      </fieldset>
    </form>
    <ShoppingCartError
      v-if="shoppingCartStore.discountError"
      id="discount-error"
      :error="shoppingCartStore.discountError"
      type="line-item"
    />
    <ul ref="discountsList" aria-live="polite">
      <li v-for="(_, key, index) in props.discounts" :key="index">
        <button
          class="btn btn--profile btn--small"
          title="fjern rabattkode"
          @click="(event) => handleRemoveDiscountCode(event, key)"
        >
          {{ key }}<Icon type="close" />
        </button>
      </li>
    </ul>
  </div>
</template>
